/* global rp$, window */
import './banner_vpicker.scss';

var THE_SCRIPT = function () {
	const lazyLoad = require('public/js/lib/image-lazy-load')();
	const $onReady = require('public/js/lib/on-ready');

	/********************************************
	 * Load Javascript for the Banner Vpicker Module
	 * -----------------------------------------
	 * @section banner_vpicker_js
	 ********************************************/
	(function BannerVPickerModule() {
		$onReady({
			rp$, window,
			label: 'banner_vpicker',
			fn: function () {
				const $vpicker_sliders = rp$('.banner-vpicker-slider');

				rp$.each($vpicker_sliders, function BannerVpickerSlider_Iterator(idx, vpicker_slider_ele) {
					const $vpicker_slider = rp$(vpicker_slider_ele);

					let timeout_val = $vpicker_slider.data('timeout');
					if(!timeout_val) {
						timeout_val = 4000;
					}

					if($vpicker_slider.find('li').length > 1) {
						$vpicker_slider.addClass('owl-carousel').owlCarousel({
							autoplay: true,
							autoplayTimeout: timeout_val,
							loop: false,
							rewind: true,
							dots: true,
							lazyLoad: true,
							items: 1,
						});
					}

					//For some lazy loaded images in owl-carousel we need to force the parent wrapper to have the correct height after the image has loaded
					function resizeImageWrapper(image, imageParent) {
						const $imageParent = rp$(imageParent);
						const $imageLink = $imageParent.find('a');
						const $owlStageOuter = $imageParent.closest('.owl-stage-outer');
						const $bannerSlider = $imageParent.closest('.banner-vpicker-slider');

						//If the image height changes (on resize) we need to adjust the size of the parent and wrapper to match new image size
						function updateParentHeight() {
							const height = image.height;
							if($imageParent.height() !== height) {
								$imageParent.height(height);
								$imageLink.height(height);
								$owlStageOuter.height(height);
								$bannerSlider.height(height);
							}

							window.requestAnimationFrame(updateParentHeight);
						}

						window.requestAnimationFrame(updateParentHeight);
					}

					// Reattach the lazy load script after the owl carousel has cloned the images to ensure that cloned images load correctly
					lazyLoad.attach($vpicker_slider.find('img.placeholder'), resizeImageWrapper);
				});
			},
		});
	})();
};

try {
	// Because __CURRENT_SCRIPT__ is injected by plugin Client, we need this here to try and pass it up to the plugin code
	__CURRENT_SCRIPT__ = THE_SCRIPT;
} catch (e) {
	THE_SCRIPT();
}
